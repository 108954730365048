:root {
  --margin-unit: 1rem;
}

body {
  background-color: #f8f8f8;
}

div {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  justify-content: center;
}

.card {
  width: 50%;
  padding: 0;
  margin-left: auto;
  margin-right: auto;
}

h1,
h3 {
  font-family: QuanSlim-Regular;
  font-size: 3rem;
  color: rgb(123, 123, 218);
  
}
.titleText {
  justify-content: center;
  text-align:center;
}

.cardContainer {
  display:flex;
  align-items: center;
  margin-bottom: 1rem;
  justify-content: center;
  flex-direction: column;
  padding: 0;
}

.main {
  margin: 0 var(--margin-unit);
}

footer {
  background-color: #bdbbbb;
  width: 100%;
  text-align: center;
  align-content: center;
}

.artistStatement {
  width: 100%;
  margin: 0;
  padding: var(--margin-unit);
  text-align: center;
}

.artistStatement img {
  height: auto;
  width: 50%;
  float: center;
}

#instagram {
  background-image: url(./icons/ig.png);
  display: block;
  margin: 0 auto;
  text-indent: -9999px;
  width: 3rem;
  height: 3rem;
}

/* Add alt text for accessibility */
img {
  alt: "";
}

/* Media Queries */
@media only screen and (min-width: 768px) {
  .main {
    margin: 0;
    padding: 0 var(--margin-unit);
  }
  h1,
  h3 {
    font-size: 4vh;
  }

  .artistStatement {
    width: 50%;
    padding: 5vh 10px;
  }
}

@media only screen and (max-width: 767px) {

  .modal {
    width: 50%;
    height: auto;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
  }
}
